import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Use this guide to contribute to the theme. We’ll show you how to get the development environment set up as quickly as possible so you can start contributing.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Project setup</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Development</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Work in a branch</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Sass and CSS Modules</AnchorLink>
  <AnchorLink mdxType="AnchorLink">VS Code</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Test pages</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Publishing</AnchorLink>
    </AnchorLinks>
    <h2>{`Project setup`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to `}<a parentName="p" {...{
            "href": "https://github.com/carbon-design-system/gatsby-theme-carbon"
          }}>{`gatsby-theme-carbon`}</a>{` and click the `}<inlineCode parentName="p">{`Fork`}</inlineCode>{` button in the top-right corner.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After it’s finished, click on the `}<inlineCode parentName="p">{`Clone or Download`}</inlineCode>{` button and copy the contents.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In your terminal, clone the repo into your directory of choice`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`git clone [PASTE_URL_HERE]
cd gatsby-theme-carbon
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When you clone your forked repo the `}<inlineCode parentName="p">{`origin`}</inlineCode>{` is set to your fork by default. You’ll want to add a remote that points to the upstream repo.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`git remote add upstream git@github.com:carbon-design-system/gatsby-theme-carbon.git
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In your terminal, verify that the remotes have been set`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`git remote -v
`}</code></pre>
      </li>
    </ol>
    <h2>{`Development`}</h2>
    <p>{`We use `}<a parentName="p" {...{
        "href": "https://yarnpkg.com/lang/en/docs/install/"
      }}>{`yarn`}</a>{` and yarn workspaces to develop the Carbon Gatsby theme. This allows us to have a development environment that’s closely linked to the theme with minimal setup. Run `}<inlineCode parentName="p">{`yarn install`}</inlineCode>{` to install all of the projects dependencies.`}</p>
    <p>{`This project has two packages: the actual theme package (`}<inlineCode parentName="p">{`gatsby-theme-carbon`}</inlineCode>{`) and the `}<inlineCode parentName="p">{`example`}</inlineCode>{` package. The example package emulates a project which uses the theme. Its only dependencies are Gatsby, React, and the adjacent theme package. The `}<inlineCode parentName="p">{`example`}</inlineCode>{` package also serves as the theme’s documentation and `}<a parentName="p" {...{
        "href": "https://gatsby-theme-carbon.now.sh"
      }}>{`website`}</a>{`; it’s deployed on every merge to master.`}</p>
    <p>{`New theme development will happen in the theme package while documentation and testing of that change will occur through changes in the example directory.`}</p>
    <Title mdxType="Title">Development scripts</Title>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`yarn dev`}</inlineCode>{` – start the development environment`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn dev:clean`}</inlineCode>{` – clear cache and restart dev`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn format`}</inlineCode>{` – format your code with prettier`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn lint`}</inlineCode>{` - check for errors in your javascript`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn test:prefix`}</inlineCode>{` – build and serve with a path prefix`}</li>
    </ul>
    <h2>{`Work in a branch`}</h2>
    <p>{`You should always start a new project by pulling upstream changes into master and then creating a new branch. This workflow ensures you don’t accidentally commit anything to your master branch and get stuck when trying to open a pull request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git checkout master
git pull upstream master
git checkout -b my-branch-name
`}</code></pre>
    <p>{`When you’re ready to open a pull request, push to your origin remote.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git push origin my-branch-name
`}</code></pre>
    <p>{`You’ll get a message in your terminal with a URL to open up a pull request in the upstream repository. Navigate to that URL and be sure to give a detailed summary of your pull request in the title and body section of the form.`}</p>
    <h2>{`Sass and CSS Modules`}</h2>
    <p>{`For internal theme components we use `}<a parentName="p" {...{
        "href": "https://sass-lang.com/documentation/syntax"
      }}>{`Sass`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/css-modules/css-modules#css-modules"
      }}>{`CSS Modules`}</a>{`. This allows us to take advantage of the Carbon Design System resources while not worrying about className collisions. By default, each `}<inlineCode parentName="p">{`.scss`}</inlineCode>{` file will be supplied with all of the Carbon Sass `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/packages/components/src/globals/scss/_vars.scss"
      }}>{`variables`}</a>{`: color, spacing, theme, and motion, as well as type and layout mixins, are imported automatically.`}</p>
    <p>{`You should colocate your stylesheet with the component(s) that import it. If the component is `}<inlineCode parentName="p">{`TreeView`}</inlineCode>{` then the stylesheet should be `}<inlineCode parentName="p">{`TreeView.module.scss`}</inlineCode>{`. All contained within the `}<inlineCode parentName="p">{`TreeView`}</inlineCode>{` directory.`}</p>
    <h3>{`CSS Modules`}</h3>
    <p>{`You don’t need to prefix your classes as CSS Modules will generate unique class names for you. Import the class from the `}<inlineCode parentName="p">{`.scss`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "path=TreeView.module.scss",
        "path": "TreeView.module.scss"
      }}>{`.treeView {
  color: $text-01;
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=TreeView.js",
        "path": "TreeView.js"
      }}>{`import { treeView } from './style.css';

const TreeView = props => <div className={treeView} />;
`}</code></pre>
    <p>{`For conditionally applying class names, use the `}<inlineCode parentName="p">{`classname`}</inlineCode>{` library. Note how we’re using a `}<a parentName="p" {...{
        "href": "https://tylermcginnis.com/computed-property-names/"
      }}>{`computed property name`}</a>{` for the property being based to `}<inlineCode parentName="p">{`cx`}</inlineCode>{`. That’s because the className isn’t literally `}<inlineCode parentName="p">{`"long"`}</inlineCode>{` it’s a value generated by CSS Modules and placed in the `}<inlineCode parentName="p">{`long`}</inlineCode>{` variable.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=TreeView.js",
        "path": "TreeView.js"
      }}>{`import cx from 'classname';
import { treeView, long } from './style.css';

const TreeView = props => {
  const className = cx(treeView, {
    [long]: props.long,
  });
  const TreeView = props => <div className={className} />;
};
`}</code></pre>
    <p>{`If you need to target a global class not processed by CSS Modules, you can do so with the `}<a parentName="p" {...{
        "href": "https://github.com/css-modules/css-modules#exceptions"
      }}>{`global selector`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`:global(.bx--grid) .codeBlock {
  @include type-style('code-01');
}
`}</code></pre>
    <h2>{`VS Code`}</h2>
    <p>{`To get linting error feedback while writing javascript and SCSS in VS Code, install the `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=shinnn.stylelint"
      }}>{`stylelint`}</a>{` and `}<a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint"
      }}>{`ESlint`}</a>{` extensions. We use ESLint’s Prettier rules to format and lint all of our JavaScript in one pass. To get your code to format properly on save, add the following to a `}<inlineCode parentName="p">{`.vscode/settings.json`}</inlineCode>{` in the root of your project`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "path=.vscode/settings.json",
        "path": ".vscode/settings.json"
      }}>{`{
  "editor.formatOnSave": true,
  "[javascript]": {
    "editor.formatOnSave": false
  },
  "[javascriptreact]": {
    "editor.formatOnSave": false
  },
  "eslint.autoFixOnSave": true,
  "prettier.disableLanguages": ["javascript", "javascriptreact"]
}
`}</code></pre>
    <p>{`To lint the entire project and get errors in your `}<inlineCode parentName="p">{`Problems`}</inlineCode>{` tray, you can add the following to a `}<inlineCode parentName="p">{`.vscode/tasks.json`}</inlineCode>{` file in the root of your project. You can run these tasks with `}<inlineCode parentName="p">{`cmd+shift+d`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "path=.vscode/tasks.json",
        "path": ".vscode/tasks.json"
      }}>{`{
  "version": "2.0.0",
  "tasks": [
    {
      "type": "npm",
      "script": "lint:js",
      "problemMatcher": "$eslint-stylish"
    },
    {
      "type": "npm",
      "script": "lint:scss",
      "problemMatcher": {
        "owner": "stylelint",
        "fileLocation": ["relative", "\${workspaceFolder}"],
        "pattern": [
          {
            "regexp": "^([^\\\\s].*)$",
            "file": 1
          },
          {
            "regexp": "^\\\\s+(\\\\d+):(\\\\d+)\\\\s+(✖|warning)\\\\s+(.*)\\\\s\\\\s+(.*)$",
            "line": 1,
            "column": 2,
            "severity": 3,
            "message": 4,
            "code": 5,
            "loop": true
          }
        ]
      }
    }
  ]
}
`}</code></pre>
    <h2>{`Test pages`}</h2>
    <p>{`If you want to add examples of what you are working on or see changes you’ve made, you can add an MDX file to `}<inlineCode parentName="p">{`packages/src/pages/test`}</inlineCode>{` that will be visible at `}<inlineCode parentName="p">{`(your-server-name)/test/(added-file)`}</inlineCode>{` during development. If you do add a page to the `}<inlineCode parentName="p">{`/test`}</inlineCode>{` directory, update the below list with the file you added and its purpose to be included with your pull request.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/test/spacing-audit"
        }}><inlineCode parentName="a">{`Spacing audit`}</inlineCode></a>{`: use this page to test spacing around components when combined in common patterns.`}</li>
    </ul>
    <h2>{`Publishing`}</h2>
    <ol>
      <li parentName="ol">{`figure out if it’s patch or feature`}</li>
      <li parentName="ol">{`from the root of the package run `}<inlineCode parentName="li">{`lerna publish patch`}</inlineCode>{` or `}<inlineCode parentName="li">{`lerna publish minor`}</inlineCode></li>
      <li parentName="ol">{`git push upstream master —follow-tags`}</li>
      <li parentName="ol">{`In the project’s `}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/releases"
        }}>{`release tab`}</a>{`, edit the new release to include a summary of new changes`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      